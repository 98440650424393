// config.js

const port = 8000; // Puerto en el que está escuchando el backend

// Obtener la IP del servidor actual
const hostname = window.location.hostname;
const protocol = window.location.protocol;

// Construir la URL del backend
const BACKEND_URL = `${protocol}//${hostname}:${port}`;

export default BACKEND_URL;