// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

.main {
  font-family: "Inter", sans-serif;
  background-color: #000000;
  color: white;
}

.flex {
  display: flex;
}

.contentContainer {
  padding: 5px 20px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,SAAA;EACA,UAAA;EACA,sBAAA;AADJ;;AAIA;EACI,qBAAA;EACA,cAAA;AADJ;;AAIA;EACI,gCAAA;EACA,yBCdM;EDeN,YCXS;ADUb;;AAIA;EACI,aAAA;AADJ;;AAIA;EACI,iBAAA;EACA,WAAA;AADJ","sourcesContent":["@import \"./variables.scss\";\n\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\na{\n    text-decoration: none;\n    color: inherit;\n}\n\n.main{\n    font-family: \"Inter\", sans-serif;\n    background-color: $main-bg;\n    color: $main-color;\n}\n\n.flex{\n    display: flex;\n}\n\n.contentContainer{\n    padding: 5px 20px;\n    width: 100%;\n}","// BG\n$main-bg: #000000;\n$soft-bg: #384256;\n$dark-bg: #222b3c;\n// Text\n$main-color: white;\n$soft-color: #ddd;\n$dark-color: #2a3447;\n\n// System\n$buttom-menu-background: linear-gradient(#0a71b1 0%, #25aff3 77%, #0a71b1 100%);\n$font-family-system: \"Saira\", \"Lucida Console\", \"Courier New\", \"monospace\";\n$system-background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(63, 63, 63) 50%, rgb(0, 0, 0) 100%);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
