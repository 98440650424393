import "./terminos.scss"

const Terminos = ({children, isOpen, onClose}) => {
    return(isOpen &&(
        <div className="react-modal-overlay" onClick={onClose}>
            <div className="react-modal-wrapper" onClick={e => e.stopPropagation()}>
                <div className="react-modal-content">
                    {children}
                    <button type="button" onClick={onClose}>Cerrar</button>
                </div>
            </div>
        </div>
    ))
}

export default Terminos
