import React from "react";
import { useState } from "react";
import "./about.scss";
import Terminos from "../Terminos/Terminos";

function About() {

  const [openModal, setOpenModal] = useState(false)

  return (
    <div className="about">
      <br />
      <h2 className="text_about">Acerca de K-SPER-Cloud</h2>
      <br />
      <div className="logos">
        <img src="./img/logo-ekeme.png" alt="" className="iconEkeme_about" />
        <img src="./img/logoks.png" alt="" className="logoKSPER_about" />
      </div>
      <div className="text_body">
        <p>
          Los logotipos K-SPER y E-kéme son marcas comerciales de E-kéme, todos
          los derechos reservados.
        </p>
      </div>

      <div className="termino_condiciones">
        <button className="bt_terminos" onClick={() => setOpenModal(!openModal)}>Términos y condiciones</button>
      </div>

      <Terminos className="modal_terminos" isOpen={openModal} onClose={() => setOpenModal(false)}>
        <p className="text_terminos">Terminos y condiciones para el uso de el software K-SPER station</p>
      </Terminos>
    </div>
  );
}

export default About;
