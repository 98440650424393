import React, { useEffect, useState } from 'react'
import './start.scss'

// importar componentes para consultar usuarios y redireccionamiento
import { useNavigate } from "react-router-dom";
import { useUser } from '../../../UserContext'; // Importa el hook useUser

// Iportar componentes para el inicio
import LandingClient from '../Landing Client/LandingClient';
import LandingAdmin from '../Landing Admin/LandingAdmin';

function Start() {
    // Con esta variable recupero el usuario que esta en la variable de contexto
    const { user } = useUser(); // Obtiene el objeto de usuario del contexto
    // Creo una variable para poder navegar las rutas existentes
    const navigate = useNavigate(); // Hook para la navegación

    // Variables para guardar el nombre de usuario y el grupo al que pertenecen para usar según se de el caso, la primera vez tiene datos vacios ""
    const [nameGroup, setnameGroup] = useState(""); // Estado para almacenar

    // Este es un método de React, se utiliza para ejecutar efectos secundarios en componentes funcionales
    // comprueba si la variable 'user' está definida. Si 'user' no está definida (es decir, si el usuario no está autenticado),
    // redirige al usuario a la página de inicio
    useEffect(() => {
        // Llamar a navigate() dentro de un useEffect
        if (!user) {
            // Si no se esta logeado, mandara a
            navigate('/login');
        } else {
            // Siempre que se quieran utilizar variables, estas deben ser alteradas con metodos SET para cuidar su integridad y seguridad
            // No se pueden utilizar directamente en el dom si no es atravez de su variable const con valores SET
            setnameGroup(user.group);
        }
    }, [navigate, user]); // [] significa que se ejecutará solo una vez al montar el componente
    if (!user || nameGroup === null) {
        return <p>Cargando...</p>;
    }
    return (
        <div>
            {(nameGroup !== 'Admin' && nameGroup !== '' &&
                <LandingClient />
            )}
            {(nameGroup === 'Admin' &&
                <LandingAdmin />
            )}

        </div>
    )
}

export default Start
