import React, { useState, useEffect } from 'react';
import './landingAdmin.scss';
import SingleLine from './single_line';

function LandingAdmin() {
  // const [measurerInfo, setMeasurerInfo] = useState(null);
  // // const [loading, setLoading] = useState(true);

  // const tipoMedidor = 1;

  // // Obtener el valor inicial del parqueIndustrial del localStorage
  // const [parqueIndustrial, setParqueIndustrial] = useState(localStorage.getItem('parque_industrial'));



  // useEffect(() => {
  //   const fetchMeasurerInfo = async () => {
  //     try {
  //       const data = await getRequest(parqueIndustrial, tipoMedidor);
  //       setMeasurerInfo(data);
  //       // setLoading(false);
  //       console.log(measurerInfo);
  //     } catch (error) {
  //       console.error('Error al obtener información:', error);
  //     }
  //   };

  //   fetchMeasurerInfo();
  // }, [parqueIndustrial]);

  return (
    <div>
      <SingleLine />
    </div>
  )
}

export default LandingAdmin