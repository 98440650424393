import React, { useState, useEffect, useRef } from 'react'
import './landingClient.scss'
import { getRequest } from '../../../ApiRequest.js'; // Importa el hook useUser desde el contexto de usuario
import { useUser } from "../../../UserContext"; // Importa el hook useUser
import { useNavigate } from "react-router-dom";

function LandingClient() {
    const [measurerInfo, setMeasurerInfo] = useState([]);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const imgRef = useRef(null);
    const navigate = useNavigate(); // Hook para la navegación
    const { logout } = useUser(); // Extrae la función logout del contexto de usuario

    const tipoMedidor = 1;

    const handleImageLoad = (event) => {
        setWidth(event.target.offsetWidth);
        setHeight(event.target.offsetHeight);
        // Actualizar el estilo del elemento <p>
        const fontSize = `${event.target.offsetWidth / 30}px`; 
        const pElements = document.getElementsByClassName('p-medidor');
        for (let i = 0; i < pElements.length; i++) {
            pElements[i].style.fontSize = fontSize;
        }
        // Title medidor
        const titleMedidor = document.getElementById('nombre-medidor');
        titleMedidor.style.fontSize = `${event.target.offsetWidth / 20}px`
        // Actualiza width y height 
        const divDatosMedidor = document.getElementById('mediciones-medidor');
        divDatosMedidor.style.width = `${event.target.offsetWidth / 1.55}px`;
        divDatosMedidor.style.height = `${event.target.offsetHeight / 2.30}px`;
    };

    const handleResize = () => {
        const { current: img } = imgRef;
        if (img) {
            const { offsetWidth, offsetHeight } = img;
            // Actualizar el estilo del elemento <p>
            const fontSize = `${offsetWidth / 30}px`;
            const pElements = document.getElementsByClassName('p-medidor');
            for (let i = 0; i < pElements.length; i++) {
                pElements[i].style.fontSize = fontSize;
            }
            // Title medidor
            const titleMedidor = document.getElementById('nombre-medidor');
            titleMedidor.style.fontSize = `${offsetHeight / 20}px`
            // Actualiza width y height 
            const divDatosMedidor = document.getElementById('mediciones-medidor');
            divDatosMedidor.style.width = `${offsetWidth / 1.55}px`;
            divDatosMedidor.style.height = `${offsetHeight / 2.30}px`;
        }
    };

    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(null);
            setHeight(null);
        };

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const fetchMeasurerInfo = async () => {
            try {
                const data = await getRequest(localStorage.getItem('parque_industrial'), tipoMedidor);
                console.log(data);
                setMeasurerInfo(data["0"]);
            } catch (error) {
                if (error.response.status === 401) {
                    console.error('Unauthorized');
                    logout();
                    navigate('/');
                  }
                console.error('Error al obtener información:', error);
            }
        };

        fetchMeasurerInfo();
        const intervalId = setInterval(() => {
            fetchMeasurerInfo();
        }, 1 * 1000); // x es el tiempo en segundos que quieres esperar entre cada ejecución

        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId);

    }, []);


    return (
        <div>

            {/* Todo lo demas es código HTML común pero ojo, no es nativo, ya que es una forma de resumirlo para que como programado lo escribamos como esta  */}
            {/* acostumbrado, pero realmente es código Js por lo cual la mayoría de etiquetas existen y se configuran igual pero hay una particularidad */}
            {/* El paramentro class no se puede usar ya que es una palabra reservada de Js y en su lugar se utiliza className */}
            {/* Por ende esto hace tener buenas prácticas y todo el Css se consulta en un archivo Scss que se importa al principio de este documento */}
            <div className='area-superior'>
                <img ref={imgRef} className='imgMedInicio' src="./img/Med-KSPER3.png" alt="" onLoad={handleImageLoad} />
                <div className='mediciones-medidor' id='mediciones-medidor'>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-10'>
                            <p className='p-medidor nombre-medidor' id='nombre-medidor'>MEDIDOR: {measurerInfo[0]}</p>
                        </div>
                        <div className='col-1'></div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[2]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">kV</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[3]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">kV</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[4]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">kV</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[5]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">A</p>
                                </div>
                            </div>
                        
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[6]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">A</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[7]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">A</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[8]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">%</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[9]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">kW</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[10]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">kW</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-8 flex-right'>
                                    <p className="p-medidor">{measurerInfo[11]}</p>
                                </div>
                                <div className='col-2 flex-left'>
                                    <p className="p-medidor">MWh</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-1'></div>
                        <div className='col-10'>
                            <p className="p-medidor nombre-medidor">{measurerInfo[12]} MWh</p>
                        </div>
                       
                    </div>
                </div>
            </div>



            {/*
             <div className='mediciones-inicio'>
                <h1>RESUMEN MES ANTERIOR</h1>
                <div className='tabla-comparacion'>
                    <div className='row'>
                        <div className='col-2 border-f size-tel'>

                        </div>
                        <div className='col-10 size-tel'>
                            <div className='row'>
                                <div className='col-6 border-f size-tel'>
                                    Demanda (kW)
                                </div>
                                <div className='col-6 border-f size-tel'>
                                    Energía (kWh)
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-2 border-f size-tel'>
                            Medidor
                        </div>
                        <div className='col-10 size-tel'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-3 border-f size-tel'>
                                            Mensual
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            Punta
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            Equivalente
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            Aplicable
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-24 border-f size-tel'>
                                            Base
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            Intermedia
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            SemiPunta
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            Punta
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            Total
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-2 border-f size-tel'>
                            Los Nogales
                        </div>
                        <div className='col-10 size-tel'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-3 border-f size-tel'>
                                            1,080.498
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            1,180.686
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            1,180.686
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-24 border-f size-tel'>
                                            520.222.144
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            103.463
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            623,685.362
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-2 border-f size-tel'>
                            SE COMPACTA
                        </div>
                        <div className='col-10 size-tel'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-3 border-f size-tel'>
                                            1,054.838
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            1,143.66
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            1,143.66
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-24 border-f size-tel'>
                                            503.869.337
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            100.257.813
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            604,127.151
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-2 border-f size-tel'>
                            Participación
                        </div>
                        <div className='col-10 size-tel'>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-3 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-3 border-f size-tel'>
                                            0
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='row'>
                                        <div className='col-24 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            0
                                        </div>
                                        <div className='col-24 border-f size-tel'>
                                            0
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            */}
        </div>
    )
}

export default LandingClient