import React from 'react'
import './footer.scss'
import LinksMobile from '../Links mobile/LinksMobile';


function Footer() {
  return (
    <div className='footer d-sm-block d-md-none '>
      <LinksMobile/>
    </div>
  )
}

export default Footer