import React from 'react'
import './supheader.scss'

export default function SupHeader() {
  return (
    <div className='SupHeader'>
        <div className='logo'>
          <img src="./img/logoks.png" alt="" className='logoKspr'/>
        </div>
        <img src="./img/logo-ekeme.png" alt="" className='iconEkeme'/>
        <img src="./img/same-floridoL.png" alt="" className='logoFlorido'/>
    </div>


  )
}
