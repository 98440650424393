import axios from 'axios';
import BACKEND_URL from './config.js'; // Importa BACKEND_URL desde el archivo config.js

// ApiRequest.js

// Función para hacer una solicitud GET a una API
export async function getRequest(parqueIndustrial, tipoMedidor) {
  try {
    const token = localStorage.getItem('token'); // Obtener el token del almacenamiento local

    const response = await axios.get(`${BACKEND_URL}/api/get_measurer_info/`, {
      params: {
        parqueIndustrial: parqueIndustrial,
        tipoMedidor: tipoMedidor
      },
      headers: {
        'Authorization': `Bearer ${token}`, // Incluye el token de autenticación en el encabezado de autorización
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    } 
    else {
      // console.error('Error:', response.statusText);
    }
  } catch (error) {
    // console.error('Error en la solicitud GET:', error);
    throw error;
  }
}

// Función para hacer una solicitud GET a una API
export async function getParckInfo() {
  try {
    const token = localStorage.getItem('token'); // Obtener el token del almacenamiento local

    
    const response = await axios.get(`${BACKEND_URL}/api/get_park_info/`, {
      headers: {
        'Authorization': `Bearer ${token}`, // Incluye el token de autenticación en el encabezado de autorización
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data;
    } 
    else {
      // console.error('Error:', response.statusText);
    }
  } catch (error) {
    // console.error('Error en la solicitud GET:', error);
    throw error;
  }
}