// En DiagramComponent.js

import React, { Component } from 'react';
import { getRequest } from '../../../ApiRequest.js'; // Importa el hook useUser desde el contexto de usuario


class DiagramComponent extends Component {

    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.diagram = null;
        this.values = null;
        var height = window.innerHeight;
        if(height < 600)
        {
            height = 600;
        }
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: height
        };
    }

    tipoMedidor = 2;

  
    componentDidMount() {
        // alert("start");
        const canvas = this.canvasRef.current;
        this.diagram = new Diagram(canvas, this.props.url);
        window.addEventListener('resize', this.updateWindowDimensions);
        this.diagram.meters = { 0: ["XB", 1, 2, 3, 4, 5], 1: ["ESSILOR", 1, 2, 3, 4, 5] };
        this.diagram.measurer_pr = { 0: ["XB", 1, 2, 3, 4, 5] };
        this.diagram.measurer_perd ={ 0: ["XB", 1, 2, 3, 4, 5] };
        var new_dim = {
            "windowWidth": window.innerWidth,
            "windowHeight": window.innerHeight
        };
        this.fetchMeasurerInfo(1, this.diagram, new_dim);
    }
    
    /*componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevValues, prevState) {
        if (prevValues !== this.values || prevState.windowWidth !== this.state.windowWidth || prevState.windowHeight !== this.state.windowHeight) {
            if (this.diagram) {
                this.diagram.clear();
                window.addEventListener('resize', this.updateWindowDimensions);
                this.diagram._valor1 = 123;
                this.diagram.update_view(this.state.windowWidth - 35, this.state.windowHeight - 158);
                this.diagram.load();
            }
        }
    }*/

    updateWindowDimensions = () => {
        //alert("Change");
        var height = window.innerHeight;
        if(height < 600)
        {
            height = 600;
        }
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: height
        });
        this.diagram.clear_all();
        this.diagram.update_view(window.innerWidth - 35, height - 158);

        var new_dim = {
            "windowWidth": window.innerWidth,
            "windowHeight":height
        };
        this.fetchMeasurerInfo(1, this.diagram, new_dim);
        
    };

    render() {
        const { windowWidth, windowHeight } = this.state;
        // console.log(this.state)
        return <canvas ref={this.canvasRef} width={windowWidth - 35} height={windowHeight - 158} />;
    }


    fetchMeasurerInfo = async (tipoMedidor, diagrma, dim) => {
        var height = window.innerHeight;
        if(height < 600)
        {
            height = 600;
        }
        
        if (dim["windowWidth"] === window.innerWidth || dim["windowHeight"] === height) {
            height = window.innerHeight;
            if(height < 600)
            {
                height = 600;
            }
            var new_dim = {
                "windowWidth": window.innerWidth,
                "windowHeight": height
            };
            try {
                var data = [];
                if(tipoMedidor === 4)
                {
                    //diagrma.create();
                    diagrma.load();
    
                    setTimeout(() => {
                        this.fetchMeasurerInfo(1, diagrma, new_dim);
                    }, 200);
                    
                }
                if(tipoMedidor === 1)
                {
                    data = await getRequest(localStorage.getItem('parque_industrial'), 1);
                    diagrma.measurer_pr = data;
                    // console.log(data);
                    this.fetchMeasurerInfo(2, diagrma, new_dim);
                }
                if(tipoMedidor === 2)
                {
                    data = await getRequest(localStorage.getItem('parque_industrial'), 2);
                    diagrma.measurer_perd = data;
                    // console.log(data);
                    this.fetchMeasurerInfo(3, diagrma, new_dim);
                }
                if(tipoMedidor === 3)
                {
                    data = await getRequest(localStorage.getItem('parque_industrial'), 3);
                    diagrma.meters = data;
                    // console.log(data);
                    this.fetchMeasurerInfo(4, diagrma, new_dim);
                }
                
                //return data
    
            } catch (error) {
                localStorage.removeItem('token'); // Eliminamos el token del almacenamiento local
                window.location.href = "/";
                return -1
            }
        }
        else
        {
            //alert("break");
        }
        
    };
}


class Diagram {

    _background = '#0b0b0f';
    _font_color = 'white';
    _range_points = 28;
    _max_value = 100;
    _min_value = 0;
    _vertical_margin = 55;
    _horizontal_margin = 50;
    _width = 1500;
    _height = 900;
    _canvas_width = this._width - (2 * this._horizontal_margin);
    _canvas_height = this._height - (2 * this._vertical_margin);
    _url;

    _meters = {};  //Medidores tipo 5 (clientes)
    _ppal = {};
    _measurer_pr = {};  //Medidor principal
    _measurer_perd = {}; //Medidor de perdidas


    constructor(canvas, url) {
        this._ctx = canvas.getContext("2d");
        this._width = canvas.width;
        this._height = canvas.height;
        this._canvas_width = this._width - (2 * this._horizontal_margin);
        this._canvas_height = this._height - (2 * this._vertical_margin);
        this._url = url;
        this._image = new Image(10, 10);
        this._image_0 = new Image(10, 10);
        this._image_1 = new Image(10, 10);
    }

    set measurer_perd(values) {

        this._measurer_perd = values;
        //console.log("Medidor perdidas: " + this._measurer_perd);

    }

    set measurer_pr(values) {

        this._measurer_pr = values;
        //console.log("Medidor principal: " + this._measurer_pr)
    }

    set meters(values) {

        this._meters = values;

    }


    load() {
        this._image.onload = () => {
            this._image_0.onload = () => {
                this._image_1.onload = () => {
                    this.create();
                }
                this._image_1.src = process.env.PUBLIC_URL + "/img/industry.png";
            }
            this._image_0.src = process.env.PUBLIC_URL + "/img/towers.png";
        }
        this._image.src = process.env.PUBLIC_URL + "/img/medidor.png";
    }

    create() {

        this._vertical_margin = this._canvas_height * .05;
        var x1 = this._canvas_width / 2;

        var y1 = (this._canvas_height - (this._vertical_margin * 2)) / 8;


        var keys = Object.keys(this._meters);
        var div = keys.length;
        var _x1 = x1 / ((div + 1) / 2);

        var _y1 = this._vertical_margin;

        var size_img =  y1 * .8;
        if(this._canvas_width < this._canvas_height)
        {
            size_img =  y1 * .8;
        }
        

        var radius = size_img / 2;
        var triangle_width = radius / 2;
        this._ctx.font = triangle_width.toString() + "px Arial";

        this._ctx.fillStyle = this._background;
        this._ctx.moveTo(this._horizontal_margin, this._vertical_margin);
        this._ctx.fillRect(0, 0, this._width, this._height);
        this._ctx.fill()
        this._ctx.strokeStyle = this._font_color;
        this._ctx.fillStyle = this._font_color;

        this._ctx.beginPath();
        //
        this._ctx.moveTo(this._horizontal_margin, this._vertical_margin);
        this._ctx.drawImage(this._image_0, x1 - (size_img / 2), _y1 , size_img, size_img);
        //this._ctx.drawImage(this._image_0, x1 - 40, y1 - triangle_width - 10, 80, 80);
        this._ctx.fillStyle = '#009800';
        this._ctx.arc(x1 - (_y1 * 2), y1 - triangle_width + (size_img / 2), (size_img / 2), 0, 2 * Math.PI);
        //this._ctx.arc(x1 - (y1 * 2), y1 - triangle_width + 40, 40, 0, 2 * Math.PI);
        this._ctx.fill();
        this._ctx.drawImage(this._image, x1 - (_y1 * 2) - (size_img / 2), y1 - triangle_width, size_img, size_img);
        //this._ctx.drawImage(this._image, x1 - (y1 * 2) - 40, y1 - triangle_width, 80, 80);
        this._ctx.fillStyle = this._font_color;
        this._ctx.fillText("CFE", x1 - (_y1 * 2) - 50, (y1 * 2));

        this._ctx.beginPath();
        this._ctx.moveTo(x1, (_y1 + y1) - triangle_width);
        this._ctx.lineTo(x1 - (triangle_width / 2), _y1 + y1);
        this._ctx.lineTo(x1 + (triangle_width / 2), _y1 + y1);
        this._ctx.fill();

        this._ctx.beginPath();
        this._ctx.lineWidth = 5;

        this._ctx.moveTo(x1,  _y1 + y1);
        this._ctx.lineTo(x1, _y1 + (y1 * 2));
        this._ctx.stroke();

        this._ctx.beginPath();
        this._ctx.moveTo(x1, _y1 + (y1 * 2));
        this._ctx.lineTo(x1 + y1, _y1 + (y1 * 2));
        this._ctx.stroke();

        this._ctx.moveTo(x1,  _y1 + (y1 * 2));
        this._ctx.lineTo(x1, _y1 + (y1 * 3) - radius);
        this._ctx.stroke();

        ////////////////////////
        this._ctx.beginPath();
        this._ctx.fillStyle = '#009800';
        this._ctx.arc(x1 + y1 + (size_img / 2), _y1 + (y1 * 2), (size_img / 2), 0, 2 * Math.PI);
        this._ctx.fill();
        this._ctx.drawImage(this._image, x1 + y1, _y1 + (y1 * 2) - (size_img / 2), size_img, size_img);

        this._ctx.fillStyle = this._font_color;
        this._ctx.fillText(this._measurer_pr[0][0], x1 + y1 + size_img + triangle_width,  _y1 + (y1 * 2) - 30);
        this._ctx.fillText(this._measurer_pr[0][2], x1 + y1 + size_img + triangle_width,  _y1 + (y1 * 2));
        this._ctx.fillText("kV", x1 + y1 + size_img + ((triangle_width + 2) * 5),  _y1 + (y1 * 2));
        this._ctx.fillText(this._measurer_pr[0][3], x1 + y1 + size_img + triangle_width, _y1 + (y1 * 2) + ((triangle_width + 2) * 1));
        this._ctx.fillText("A", x1 + y1 + size_img + ((triangle_width + 2) * 5),  _y1 + (y1 * 2) + ((triangle_width + 2) * 1));
        this._ctx.fillText(this._measurer_pr[0][4], x1 + y1  + size_img + triangle_width,  _y1 + (y1 * 2) + ((triangle_width + 2) * 2));
        this._ctx.fillText("%", x1 + y1 + size_img + ((triangle_width + 2) * 5),  _y1 + (y1 * 2) + ((triangle_width + 2) * 2));
        this._ctx.fillText(this._measurer_pr[0][5], x1 + y1 + size_img + triangle_width,  _y1 + (y1 * 2) + ((triangle_width + 2) * 3));
        this._ctx.fillText("kW", x1 + y1 + size_img + ((triangle_width + 2) * 5),  _y1 + (y1 * 2) + ((triangle_width + 2) * 3));


        this._ctx.beginPath();
        this._ctx.lineWidth = 5;
        this._ctx.moveTo(x1 + radius, _y1 + (y1 * 3));
        this._ctx.arc(x1, _y1 + (y1 * 3), radius, 0, 2 * Math.PI);
        this._ctx.moveTo(x1 + radius, _y1 + (y1 * 3) + radius);
        this._ctx.arc(x1, _y1 + (y1 * 3) + radius, radius, 0, 2 * Math.PI);
        this._ctx.moveTo(x1, _y1 + (y1 * 3) + (2 * radius));
        this._ctx.lineTo(x1, _y1 + (y1 * 5));
        this._ctx.stroke();



        if(Object.keys(this._measurer_perd).length > 0){
            this._ctx.moveTo(x1, _y1 + (y1 * 4));
            this._ctx.lineTo(x1 + y1, _y1 + (y1 * 4));
            this._ctx.stroke();

            this._ctx.beginPath();
            this._ctx.fillStyle = '#009800';
            this._ctx.arc(x1 + y1 + (size_img / 2), _y1 + (y1 * 4), (size_img / 2), 0, 2 * Math.PI);
            this._ctx.fill();
            this._ctx.drawImage(this._image, x1 + y1, _y1 + (y1 * 4) - (size_img / 2), size_img, size_img);

            this._ctx.fillStyle = this._font_color;
            this._ctx.fillText(this._measurer_perd[0][0], x1 + y1 + size_img + triangle_width, _y1 + (y1 * 4) - 30);
            this._ctx.fillText(this._measurer_perd[0][2], x1 + y1 + size_img + triangle_width, _y1 + (y1 * 4));
            this._ctx.fillText("kV", x1 + y1 + size_img + ((triangle_width + 2) * 5), _y1 + (y1 * 4));
            this._ctx.fillText(this._measurer_perd[0][3], x1 + y1 + size_img + triangle_width, _y1 + (y1 * 4) + ((triangle_width + 2) * 1));
            this._ctx.fillText("A", x1 + y1 + size_img + ((triangle_width + 2) * 5), _y1 + (y1 * 4) + ((triangle_width + 2) * 1));
            this._ctx.fillText(this._measurer_perd[0][4], x1 + y1 + size_img + triangle_width, _y1 + (y1 * 4) + ((triangle_width + 2) * 2));
            this._ctx.fillText("%", x1 + y1 + size_img + ((triangle_width + 2) * 5), _y1 + (y1 * 4) + ((triangle_width + 2) * 2));
            this._ctx.fillText(this._measurer_perd[0][5], x1 + y1 + size_img + triangle_width, _y1 + (y1 * 4) + ((triangle_width + 2) * 3));
            this._ctx.fillText("kW", x1 + y1 + size_img + ((triangle_width + 2) * 5), _y1 + (y1 * 4) + ((triangle_width + 2) * 3));
        }


        this._ctx.beginPath();
        this._ctx.lineWidth = 5;
        this._ctx.moveTo(_x1, _y1 + (y1 * 5));
        this._ctx.lineTo(this._canvas_width - _x1, _y1 + (y1 * 5));

        for (let i = 1; i < div + 1; i++) {
            this._ctx.moveTo((i * _x1), _y1 + (y1 * 5));
            this._ctx.lineTo((i * _x1), _y1 + (y1 * 7) - triangle_width);
            this._ctx.stroke();

            this._ctx.beginPath();
            this._ctx.lineTo((i * _x1) - (triangle_width / 2), _y1 + (y1 * 7) - triangle_width);
            this._ctx.lineTo((i * _x1) + (triangle_width / 2), _y1 + (y1 * 7) - triangle_width);
            this._ctx.lineTo((i * _x1), _y1 + (y1 * 7));
            this._ctx.fill();
            
            this._ctx.drawImage(this._image_1, (i * _x1) - (size_img / 2), _y1 + (y1 * 7), size_img, size_img);
            this._ctx.fillText(this._meters[keys[i - 1]][0], (i * _x1) - 35, _y1 + (y1 * 8));

            this._ctx.beginPath();
            this._ctx.lineWidth = 5;
            this._ctx.moveTo((i * _x1), _y1 + (y1 * 6));
            this._ctx.lineTo((i * _x1) + (_x1 / 10), _y1 + (y1 * 6));
            this._ctx.stroke();

            this._ctx.beginPath();
            this._ctx.fillStyle = '#980000';
            if (this._meters[keys[i - 1]][1] === 1) {
                this._ctx.fillStyle = '#009800';
            }

            this._ctx.arc((i * _x1) + (_x1 / 10) + (size_img / 2), _y1 + (y1 * 6), (size_img / 2), 0, 2 * Math.PI);
            this._ctx.fill();
            this._ctx.drawImage(this._image, (i * _x1) + (_x1 / 10), _y1 + (y1 * 6) - (size_img / 2), size_img, size_img);

            this._ctx.beginPath();
            this._ctx.fillStyle = this._font_color;
            this._ctx.fillText(this._meters[keys[i - 1]][2], (i * _x1), _y1 + (y1 * 8) + ((triangle_width + 2) * 1));
            this._ctx.fillText("kV", (i * _x1) + ((triangle_width + 2) * 4), _y1 + (y1 * 8) + ((triangle_width + 2) * 1));
            this._ctx.fillText(this._meters[keys[i - 1]][3], (i * _x1), _y1 + (y1 * 8) + ((triangle_width + 2) * 2));
            this._ctx.fillText("A", (i * _x1) + ((triangle_width + 2) * 4), _y1 + (y1 * 8) + ((triangle_width + 2) * 2));
            this._ctx.fillText(this._meters[keys[i - 1]][4], (i * _x1), _y1 + (y1 * 8) + ((triangle_width + 2) * 3));
            this._ctx.fillText("%", (i * _x1) + ((triangle_width + 2) * 4), _y1 + (y1 * 8) + ((triangle_width + 2) * 3));
            this._ctx.fillText(this._meters[keys[i - 1]][5], (i * _x1), _y1 + (y1 * 8) + ((triangle_width + 2) * 4));
            this._ctx.fillText("kW", (i * _x1) + ((triangle_width + 2) * 4), _y1 + (y1 * 8) + ((triangle_width + 2) * 4));
        }

        this._ctx.stroke();
    }

    clear_all() {
        this._ctx.clearRect(0, 0, this._width, this._height);
    }

    clear() {
        var x1 = this._canvas_width / 2;
        var y1 = (this._canvas_height - (this._vertical_margin * 2)) / 6;
        var keys = Object.keys(this._meters);
        var div = keys.length;
        var _x1 = x1 / ((div + 1) / 2);
        this._ctx.fillStyle = this._background;
        this._ctx.fillRect(x1 + (y1 * 2), y1 - 40, 0, 80);
        this._ctx.fillRect(x1 + (y1 * 2) + 100, y1 - 15, 130, 100);
        this._ctx.fillRect(x1 + (y1 * 2) - 20, y1 - 60, 120, 120);
        for (let i = 1; i < div + 1; i++) {
            this._ctx.fillRect((i * _x1) + (_x1 / 3), (y1 * 4) - 40, 80, 80);
            this._ctx.fillRect((i * _x1) + 40, (y1 * 5) - 15, 130, 100);
            this._ctx.fillRect((i * _x1) + (_x1 / 3) - 20, (y1 * 4) - 60, 120, 120);
        }
        this._ctx.fill();
        this._ctx.fillStyle = this._font_color;
    }

    update_view(width, height) {
        this.clear_all();
        this._width = width;
        this._height = height;
        this._canvas_width = this._width - (2 * this._horizontal_margin);
        this._canvas_height = this._height - (2 * this._vertical_margin);
        //this.create();
    }

}
export default DiagramComponent;