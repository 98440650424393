// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 700.98px) {
  .SupHeader {
    width: 100%;
    /* top | right | bottom | left */
    padding: 2px 20px 1px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(#0a71b1 0%, #25aff3 77%, #0a71b1 100%);
  }
  .SupHeader .logoKspr {
    width: 200px;
  }
  .SupHeader .iconEkeme {
    width: 150px;
  }
  .SupHeader .logoFlorido {
    width: 150px;
  }
}
@media (max-width: 700.97px) {
  .SupHeader {
    width: 100%;
    /* top | right | bottom | left */
    padding: 2px 20px 1px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(#0a71b1 0%, #25aff3 77%, #0a71b1 100%);
  }
  .SupHeader .logoKspr {
    width: 150px;
  }
  .SupHeader .iconEkeme {
    width: 120px;
  }
  .SupHeader .logoFlorido {
    width: 120px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/SupHeader/supheader.scss"],"names":[],"mappings":"AAAA;EACI;IACI,WAAA;IACA,gCAAA;IACA,0BAAA;IACA,aAAA;IACA,mBAAA;IACA,8BAAA;IACA,kEAAA;EACN;EACM;IACI,YAAA;EACV;EAGM;IACI,YAAA;EADV;EAKM;IACI,YAAA;EAHV;AACF;AAQA;EACI;IACI,WAAA;IACA,gCAAA;IACA,0BAAA;IACA,aAAA;IACA,mBAAA;IACA,8BAAA;IACA,kEAAA;EANN;EAQM;IACI,YAAA;EANV;EAUM;IACI,YAAA;EARV;EAYM;IACI,YAAA;EAVV;AACF","sourcesContent":["@media (min-width: 700.98px) { \n    .SupHeader{\n        width: 100%;\n        /* top | right | bottom | left */\n        padding: 2px 20px 1px 20px;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        background: linear-gradient(#0a71b1 0%, #25aff3 77%, #0a71b1 100%); \n        \n        .logoKspr{\n            width: 200px;\n            \n            \n        }\n        .iconEkeme{\n            width: 150px;\n            \n        }\n        \n        .logoFlorido{\n            width: 150px;\n        }\n    }\n\n}\n\n@media (max-width: 700.97px) { \n    .SupHeader{\n        width: 100%;\n        /* top | right | bottom | left */\n        padding: 2px 20px 1px 20px;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        background: linear-gradient(#0a71b1 0%, #25aff3 77%, #0a71b1 100%); \n        \n        .logoKspr{\n            width: 150px;\n            \n            \n        }\n        .iconEkeme{\n            width: 120px;\n            \n        }\n        \n        .logoFlorido{\n            width: 120px;\n        }\n    }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
