import React, { useState, useEffect } from "react";
import "./login.scss";

// Componentes para crear una tarjeta con React adaptada para verse bien en mobile
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";

// importar componentes para consultar usuarios y redireccionamiento
import { useNavigate } from "react-router-dom";
import { useUser } from '../../UserContext'; // Importa el hook useUser


function Login() {
  // Variables para el manejo de inicio de sesion
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [formReady, setFormReady] = useState(false);

  const navigate = useNavigate(); // Hook para la navegación
  const { login } = useUser(); // Usa el hook useUser para acceder a la función login

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && formReady) {
      handleLogin();
    }
  };

  useEffect(() => {
    const isFormReady = username.trim() !== '' && password.trim() !== '';
    setFormReady(isFormReady);
  }, [username, password]);

  useEffect(() => {
    if (username || password) {
      setFormReady(true);
    }
  }, []); 

  // como se declara una funcion de tipo flecha, así encapsulamos el código y este jamas se vera en el dom del navegador,
  // si no que se queda en el dom virtual del servidor
  // Crea una función asincrona para que cuando se da click en el bóton que la llama se ejecute en otro proceso independiente si afectar el renderizado
  const handleLogin = async () => {
    // Para manejar errors de inicio de sesión, ya que la función lógin esta programada para regresar un error si no son las credenciales correctas
    try {
      // La variable response guarda lo que regresa la función login despues de ejecutarse
      const response = await login(username, password); // Utiliza la función login del contexto para iniciar sesión

      // Con ello comprobamos si existe una respuesta, si no de lo contrario hay un error
      if(response){
        setMensaje('Inicio de sesión exitoso'); // Si el inicio de sesión fue exitoso, configura el mensaje
        navigate('/');
      }else{
        console.error('Error de inicio de sesión:');
        setMensaje('Ocurrió un error al intentar iniciar sesión');
      }


    } catch (error) {
      console.error('Error de inicio de sesión:', error);
      setMensaje('Ocurrió un error al intentar iniciar sesión');
    }
  };

  return (
    <div className="login" style={{ marginTop: "15vh"}}>
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center">
          <MDBCol col="12">
            {/* Es valido poner style pero no es una buena práctica, pero si se requiere colocar ha que encerrar todo en llaves dobles para que Js entienda */}
            {/* Que son parametros y no variables, ya que si no espera que sean variables de las que se declaran en la parte superior del componente */}
            <MDBCard
              className="card my-5 mx-auto"
              style={{ borderRadius: "1rem", maxWidth: "500px" }}
            >
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                <MDBCardImage
                  src="./img/logoks.png"
                  alt="ksperLogo"
                  className="rounded-t-5 rounded-tr-lg-0"
                  fluid
                />

                <p className="text-white-50 mb-3">
                  Por favor ingrese su Usuario y Contraseña
                </p>

                <p className="text_labels">Usuario</p>
                {/* Cuando detecte un cambio al escribir un nuevo username lo guarda en la variable username por medio de su acceso SET */}
                <MDBInput
                  className="input_element"
                  wrapperClass="mb-4 w-100"
                  id="formControlLg"
                  type="email"
                  size="lg"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <p className="text_labels">Contraseña</p>
                {/* Cuando detecte un cambio al escribir un nuevo password lo guarda en la variable password por medio de su acceso SET */}
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  id="formControlLg"
                  type="password"
                  size="lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={handleKeyPress}
                />

                {/* Cuando se le da clic a este botón, es cuando se comunica asincronamente con el evento handleLogin */}
                <button className="btnLogin" onClick={handleLogin}>
                  Iniciar sesión
                </button>
                

              </MDBCardBody>
                {/* Pone el mensaje de si la sesion fue exitosa o hay error, esto depende de la respuesta del servidor */}
                {mensaje && <p className="text_login">{mensaje}</p>}
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default Login;
