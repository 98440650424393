import React from 'react'
import './linksmobile.scss'
import {
  Link
} from "react-router-dom";

function LinksMobile() {
  return (
    <div className='links-formato-mobile '>
      <Link to="/">
        <div className='menu-icon-mobile'>
          <img src="./img/icons/home.svg" alt="" width="20" height="20" className="icon svg-icons" />
        </div>
      </Link>
      <Link to="/grafica-tendencias">
        <div className='menu-icon-mobile'>
          <img src="./img/icons/user.svg" alt="" className="icon svg-icons" />
        </div>
      </Link>
      <Link to="/resumen">
        <div className='menu-icon-mobile'>
          <img src="./img/icons/settings.svg" alt="" className="icon svg-icons" />
        </div>
      </Link>
      <Link to="/estado-de-cunetas">
        <div className='menu-icon'>
        <img src="./img/icons/settings.svg" alt="" className="icon svg-icons" />
        </div>
      </Link>
      <Link to="/factura">
        <div className='menu-icon-mobile'>
          <img src="./img/icons/user.svg" alt="" className="icon svg-icons" />
        </div>
      </Link>
    </div>
  )
}

export default LinksMobile