import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
import BACKEND_URL from './config'; // Importa BACKEND_URL desde el archivo config.js

// Creamos un contexto para almacenar la información del usuario
const UserContext = createContext();

// Componente proveedor de contexto para almacenar y proporcionar la información del usuario
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Función para iniciar sesión
  const login = async (username, password) => {
    try {
      // hay que agregar a que ip o ruta se hace la petición a backend para iniciar sesión, en este caso se agrega la ip del servicio local para desarrollo
        const response = await axios.post(
            `${BACKEND_URL}/api/iniciar-sesion/`,
            { username, password },
            {
              headers: {
                'Content-Type': 'application/json',   //espera una respuesta Json (es un diccionario)
              },
            }
          );
      if(response.status !== 400){
        setUser(response.data.user); // Aquí establecemos el estado con los datos del usuario
        localStorage.setItem('token', response.data.access_token); // Guardamos el token en el almacenamiento local

        return response.data;
      }else{
        console.error('Error al iniciar sesión:', response.mensaje);
        return false;
      }

    } catch (error) {
      console.error('Error al iniciar sesión:', error);
    }
  };

// Función para cerrar sesión
const logout = () => {
    setUser(null); // Limpiamos el usuario
    localStorage.removeItem('token'); // Eliminamos el token del almacenamiento local
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook personalizado para acceder a la información del usuario y a las funciones de inicio y cierre de sesión
export const useUser = () => useContext(UserContext);