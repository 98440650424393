// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.links-formato-mobile {
  display: block;
  justify-content: space-around;
}

/* Media query para dispositivos con un ancho de pantalla máximo de 767px (generalmente dispositivos móviles) */
@media (max-width: 767px) {
  .links-formato-mobile {
    display: flex;
  }
  .menu-icon-mobile {
    margin-right: 0.9rem;
    margin-left: 0.9rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Links mobile/linksmobile.scss","webpack://./src/components/Layout/Links%20mobile/linksmobile.scss"],"names":[],"mappings":"AAAA,gBAAgB;ACEhB;EACE,cAAA;EACA,6BAAA;ADAF;;ACGA,+GAAA;AACA;EACE;IACE,aAAA;EDAF;ECGA;IACI,oBAAA;IACA,mBAAA;EDDJ;AACF","sourcesContent":["@charset \"UTF-8\";\n.links-formato-mobile {\n  display: block;\n  justify-content: space-around;\n}\n\n/* Media query para dispositivos con un ancho de pantalla máximo de 767px (generalmente dispositivos móviles) */\n@media (max-width: 767px) {\n  .links-formato-mobile {\n    display: flex;\n  }\n  .menu-icon-mobile {\n    margin-right: 0.9rem;\n    margin-left: 0.9rem;\n  }\n}","@import \"../../../styles/variables.scss\";\n\n.links-formato-mobile{\n  display: block;\n  justify-content: space-around;\n}\n\n/* Media query para dispositivos con un ancho de pantalla máximo de 767px (generalmente dispositivos móviles) */\n@media (max-width: 767px) {\n  .links-formato-mobile {\n    display: flex;\n  }\n\n  .menu-icon-mobile{\n      margin-right: 0.9rem;\n      margin-left: 0.9rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
