// Revisar al final para empezar a entender la lógica

import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";

import SupHeader from './components/Layout/SupHeader/SupHeader';
import Links from './components/Layout/Links/Links';
import Footer from './components/Layout/Footer/Footer';
import Login from './pages/Login/Login';
import About from './pages/Estaticas/About/About';
import "./styles/global.scss"

// K-sper funciones-vistas
import Start from './pages/K-sper station/Start/Start';
import Terminos from './pages/Estaticas/Terminos/Terminos';

import { UserProvider } from './UserContext'; // Importa el proveedor de contexto

function App() {


  // Layout es una variable creada por el programador bien puede llamarse de otra forma, aquí creamos una estrucutra de componentes
  // que responderan a componente en común que requieran tener estos elementos, por ejemplo ahí vemos "Outlet" que es donde se renderiza
  // el componente que se escoge según la ruta solicitada y los demas es para ver el menú, el header o footer dentro de la ruta solicitada
  // El componente Raíz "/" es Start, pero dentro encontraremos una validación que si no existe la variable de contexto del usuario me regrese al login
  // Para crearlo al iniciar sesión y tener correctas mis contraseñas registradas en el panel de Django por lo cual esta validación afectara a todos
  // las rutas children que esten en ella.
  const Layout = () => {
    return (
      <div className='main'>
        <SupHeader/>
        <Links/>
        <div className='flex'>
          <div className='contentContainer'>
            <Outlet />
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    )
  }

  // En este apartado se ponen las rutas neceserias y que componente comparten componente en comun
  // por ejemplo no todas las rutas necesitan ver el menú o los logos, login solo necesita cargar su componente para logearse
  // Entonces para encerrar estos veamos el caso de "/" que tendra hijos asociados por ejemplo Start y este tenra Layout que es la estructura de este
  // componente padre de la ruta "/"" , pero dentro puede tener varias ruta con varios componentes
  // En cambio "/login" no tiene hijos asociados pues solo ira a su componente que ocupa y listo.
  // Veamos arriba como se crea Layout ↑
  const router = createBrowserRouter([

    {
      path:"/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element: (
            <div>
              {/* Componente que renderiza el inicio de "/"" */}
              <Start />
            </div>
          ),
        },
        {
          path: "*",
          element: <Navigate to="/" />
        }
      ]
    },
    /* TO-DO Return about to children  */
    {
      path: "/about",
      element: <About />
    },
    {
      path: "/terminos",
      element: <Terminos/>
    },
    {
      path:"/login",
      element: <Login />,
    }
  ]);


  return (

    // UserProvider es un componente Hook el cual crea una variable de contexto la cual depente de que exista un usuario
    // Este usuario lo regresa el backend dentro de ese componente y si no existe ese usuario no te deja pasar a la aplicación y las demas funciones
    <UserProvider> {/* Envuelve tu RouterProvider con el UserProvider */}
      {/* Una vez se accede, RouterProvider es un componente que maneja las rutas dentro de nuestra App  */}
      {/* Conectando la ruta que se coloque en el navegador con el componente o vista correspondiente */}
      {/* Regresamos arriba a la constante router para ver que rutas tenemos ↑ */}
      <RouterProvider router={router}/>
    </UserProvider>

  );
}

export default App;
