import React, { useState, useEffect, useRef } from "react";
import "./links.scss";

import { NavLink } from "react-router-dom";

// importar componentes para consultar usuarios y redireccionamiento
import { useUser } from "../../../UserContext"; // Importa el hook useUser
import { useNavigate } from "react-router-dom";
import { getParckInfo } from '../../../ApiRequest.js'; // Importa el hook useUser desde el contexto de usuario


function Links() {

  const { logout } = useUser(); // Extrae la función logout del contexto de usuario
  const [parquesInfo, setParquesInfo] = useState([]);

  const handleLogout = () => {
    logout(); // Llama a la función logout cuando se hace clic en el NavLink
  };
  // Parala lógica de desplegar u ocultar el Dropdown
  const [botonHover, setBotonHover] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  let timer;
  const dropdownContentRef = useRef(null);

  const { user } = useUser(); // Obtiene el objeto de usuario del contexto
  const navigate = useNavigate(); // Hook para la navegación


  // Para mostrar el texto que esta en el dropdown,, por defecto se coloca el descrito a continuación
  const [selectedOption, setSelectedOption] = useState(""); // Estado para almacenar la opción seleccionada

  useEffect(() => {
    const fetchGetParques = async () => {
        try {
            const data = await getParckInfo();
            // console.log(data);
            const opciones = Object.values(data);
            setParquesInfo(opciones);
            // console.log(opciones[0].id);
            localStorage.setItem('parque_industrial', opciones[0].id); // Guardamos el token en el almacenamiento local
            setSelectedOption(opciones[0].texto); // Actualiza el estado con el nombre de la opción seleccionada
        } catch (error) {
            console.error('Error al obtener información:', error);
        }
    };

    fetchGetParques();
  }, []);



  // Evento para cuando se da click en donde se manda llamar ese metodo
  const handleOptionClick = (optionName) => {
    // var park;
    // if (optionName === "PARQUE INDUSTRIAL NOGALES") {
    //   park = "Los Nogales";
    // }
    // if (optionName === "PARQUE INDUSTRIAL LOS OLIVOS") {
    //   park = "Los Olivos";
    // }
    // if (optionName === "PARQUE INDUSTRIAL LA ENCANTADA II") {
    //   park = "La Encantada";
    // }
    localStorage.setItem('parque_industrial', optionName.id); // Guardamos el token en el almacenamiento local
    setSelectedOption(optionName.texto); // Actualiza el estado con el nombre de la opción seleccionada
    setDropdownVisible(false); // Cierra el dropdown
  };

  useEffect(() => {
    // Evento para ver donde esta pasando el mouse en el dom
    const handleMouseOver = (event) => {
      clearTimeout(timer); // Limpiar el temporizador cada vez que se active el evento

      // Verifica si donde se pasa el mouse es igual a esos id para que se mantenga desplegado, de lo contrario lo oculta
      if (
        event.target.id === "admin-btn" ||
        event.target.id === "dropdown-admin" ||
        (dropdownContentRef.current &&
          dropdownContentRef.current.contains(event.target))
      ) {
        // Para desplegar el dropDown si se cumple la condición
        setDropdownVisible(true);
      } else {
        // Ocultar el dropdown con un pequeño retraso
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = setTimeout(() => {
          setDropdownVisible(false);
        }, 100); // 100 milisegundos de retraso (ajustar según sea necesario)
      }
    };

    // Llamar a navigate() dentro de un useEffect
    if (!user) {
      navigate("/login"); // Si no existe el usuario redirige a Login para que inicie sesion, protegiendo aquellas vistas donde se ocupe este menú de rutas
    } else {
      // setnameGroup(user.group); // Se agrega a la variable grupo a que grupo pertenece el usuario
    }

    // Agrega el evento mouseover
    document.addEventListener("mouseover", handleMouseOver);

    // Asociamos el evento a la función
    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
    };
  }, [navigate, user]);



  return (
    <div className="links-formato flex ">
      <div>
        {/* Si se le da click al botón o se le pasa por encima con el mouse activa las variables para desplegar el dropdown */}
        <button
          id="admin-btn"
          className="button-menu btn-parques"
          onMouseEnter={() => {
            setBotonHover(true);
            setDropdownVisible(true);
          }}
          onMouseLeave={() => setBotonHover(false)}
        >
          {selectedOption}{" "}
          {/* Muestra el nombre seleccionado o "Selecciona Parque" si no hay selección */}
        </button>
        {/* Se deben cumplir estas condiciones para desplegar el dropdown de lo contrario se oculta */}
        {(botonHover || dropdownVisible) && (
          <nav
            className="dropdown-list w-dropdown-list"
            id="dropdown-admin"
            ref={dropdownContentRef}
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
          >
            {/* Con el evento onclick manda llamar el evento de lógia que se programo arriba, se hace con función de tipo flecha para acceder al método */}
            {parquesInfo.map((opcion, index) => (
              <button
                key={index} // Asegúrate de proporcionar una clave única para cada elemento de la lista
                className="dropdown-link w-dropdown-link button-menu"
                onClick={() => handleOptionClick(opcion)}
              >
                {opcion.texto}
              </button>
            ))}
          </nav>
        )}
      </div>
      {/* Agregamos unos botones que estan encapsulado en un LINK que al darle click redirecciona al componente en cuestion */}
      {/* <Link to="/">
        <div className='menu-icon button-menu'>
          <p className='p-icons'>Inicio</p>
        </div>
      </Link>
      <Link to="/grafica-tendencias">
        <div className='menu-icon button-menu'>
          <p className='p-icons'>Grafica de tendencias</p>
        </div>
      </Link> */}
      {/* Depende a que grupo pertenece el usuario loggeado muestra o no este botón en el DOM */}
      {/* {nameGroup === 'Admin' && ( 
        <Link to="/resumen">
          <div className='menu-icon button-menu'>
            <p className='p-icons'>Resumen</p>
          </div>
        </Link>
      )} */}
      {/* Depende a que grupo pertenece el usuario loggeado muestra o no este botón en el DOM */}
      {/* {nameGroup === 'Cliente' && ( 
          <Link to="/estado-de-cuentas">
            <div className='menu-icon button-menu'>
              <p className='p-icons'>Estados de cuenta</p>
            </div>
          </Link>
      )} */}

      {/*<Link to="/factura">
        <div className='menu-icon button-menu'>
          <p className='p-icons'>Cerrar sesión</p>
        </div>
    </Link>*/}

      <NavLink to="/" onClick={handleLogout}>
        <div className="menu-icon button-menu">
          <p className="p-icons">Cerrar sesión</p>
        </div>
      </NavLink>
    </div>
  );
}

export default Links;
