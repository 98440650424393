// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background: linear-gradient(#0a71b1 0%, #25aff3 77%, #0a71b1 100%);
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Footer/footer.scss"],"names":[],"mappings":"AAAA;EACI,kEAAA;EACA,aAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AACJ","sourcesContent":[".footer{\n    background: linear-gradient(#0a71b1 0%, #25aff3 77%, #0a71b1 100%);\n    padding: 10px;\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n    height: 60px;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
