// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.react-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.react-modal-wrapper {
  max-width: 500px;
  margin: 2rem auto;
}

.react-modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border-radius: 0.3rem;
  padding: 1rem;
}

@media (min-width: 700.98px) {
  .terminos {
    color: black;
  }
}
@media (max-width: 700.97px) {
  .terminos {
    color: aliceblue;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Estaticas/Terminos/terminos.scss"],"names":[],"mappings":"AAEA;;;EAGI,SAAA;EACA,UAAA;EACA,sBAAA;AADJ;;AAIA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,oCAAA;EACA,UAAA;AADJ;;AAIA;EACI,gBAAA;EACA,iBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,sBAAA;EACA,oCAAA;EACA,4BAAA;EACA,qBAAA;EACA,aAAA;AADJ;;AAGA;EACI;IACI,YAAA;EAAN;AACF;AAKA;EACI;IACI,gBAAA;EAHN;AACF","sourcesContent":["\n\n*,\n::before,\n::after{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.react-modal-overlay{\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    overflow-x: hidden;\n    overflow-y: hidden;\n    background-color: rgba(0,0,0,0.5);\n    z-index: 1\n}\n\n.react-modal-wrapper{\n    max-width: 500px;\n    margin: 2rem auto;\n}\n\n.react-modal-content{\n    position: relative;\n    background-color: #fff;\n    border: 1px solid rgba(0,0,0,0.3);\n    background-clip: padding-box;\n    border-radius: 0.3rem;\n    padding: 1rem;\n}\n@media (min-width: 700.98px) { \n    .terminos{\n        color: black;\n    }\n\n}\n  \n  \n@media (max-width: 700.97px) { \n    .terminos{\n        color: aliceblue;\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
